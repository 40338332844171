
















import { BlogPostSummary } from '@/models/blog/blogPostSummary';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { CommonGetters } from '@/store/modules/common/enums';
import { blogService } from '@/services/blog/blog.service';

@Component
export default class BlogPostsLatest extends Vue {
  protected loaded = false;
  protected latest: BlogPostSummary[] = Array<BlogPostSummary>();

  @Watch('language')
  public async loadLatestBlogPosts(): Promise<void> {
    this.loaded = false;
    this.latest = await blogService
      .getLatestBlogPosts()
      .finally(() => (this.loaded = true));
  }

  async created(): Promise<void> {
    if (!this.loaded) await this.loadLatestBlogPosts();
  }

  public get language(): string {
    return this.$store.getters[CommonGetters.Language];
  }
}
