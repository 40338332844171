import { BlogPost } from '@/models/blog/blogPost';
import { BlogPostSummary } from '@/models/blog/blogPostSummary';
import BaseService from '@/services/base/base.service';
import axios, { AxiosResponse } from 'axios';
import store from '@/store';
import { CommonGetters } from '@/store/modules/common/enums';

class BlogService extends BaseService {
  public async getBlogPostsList(
    page = 1,
  ): Promise<AxiosResponse<BlogPostSummary[]>> {
    return await axios.get<BlogPostSummary[]>(
      `${super.clientsApiBaseUrl()}/blog/posts?lang=${
        store.getters[CommonGetters.Language]
      }&page=${page}`,
    );
  }

  public async getLatestBlogPosts(): Promise<BlogPostSummary[]> {
    const response = await axios.get<BlogPostSummary[]>(
      `${super.clientsApiBaseUrl()}/blog/posts/latest?lang=${
        store.getters[CommonGetters.Language]
      }`,
    );
    return response.data;
  }

  public async getBlogPost(blogPostId: string): Promise<BlogPost> {
    const response = await axios.get<BlogPost>(
      `${super.clientsApiBaseUrl()}/blog/posts/${blogPostId}?lang=${
        store.getters[CommonGetters.Language]
      }`,
    );
    return response.data;
  }
}

export const blogService = new BlogService();
