




























































import { BlogPostSummary } from '@/models/blog/blogPostSummary';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { CommonGetters } from '@/store/modules/common/enums';
import { blogService } from '@/services/blog/blog.service';

@Component
export default class BlogPostsContainer extends Vue {
  protected loaded = false;
  protected loadingMore = false;
  protected entrynews: BlogPostSummary[] = Array<BlogPostSummary>();
  protected currentPage = 1;
  protected totalPages = 1;

  @Watch('language')
  public async loadBlogPosts(): Promise<void> {
    this.loaded = false;
    this.entrynews = Array<BlogPostSummary>();
    await this.retrievePosts();
    this.loaded = true;
  }

  async created(): Promise<void> {
    if (!this.loaded) await this.loadBlogPosts();
  }

  public get language(): string {
    return this.$store.getters[CommonGetters.Language];
  }

  public async nextPage(): Promise<void> {
    let nextPage = this.currentPage + 1;
    this.loadingMore = true;
    await this.retrievePosts(nextPage);
    this.loadingMore = false;
  }

  public morePostsToLoad(): boolean {
    return this.currentPage < this.totalPages;
  }

  private async retrievePosts(nextPage = 1): Promise<void> {
    let response = await blogService.getBlogPostsList(nextPage);
    this.parseHeaders(response.headers);
    this.entrynews = this.entrynews.concat(response.data);
  }

  private parseHeaders(headers: any): void {
    if (typeof headers['x-pagination'] !== 'undefined') {
      const xpagination = JSON.parse(headers['x-pagination']);
      this.currentPage = Number(xpagination.currentPage);
      this.totalPages = Number(xpagination.totalPages);
    }
  }
}
